// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutKhanjyan_bodyContainer__Gw705 {
  width: 80%;
  margin: 0 auto;
  padding: 10rem 0;
}

@media only screen and (max-width: 414px) {
  .AboutKhanjyan_bodyContainer__Gw705 {
    padding: 3rem 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/aboutKhanjyanPage/AboutKhanjyan.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,eAAA;EACN;AACF","sourcesContent":[".bodyContainer{\n    width: 80%;\n    margin: 0 auto;\n    padding: 10rem 0;\n}\n\n@media only screen and (max-width: 414px){\n    .bodyContainer{\n        padding: 3rem 0 ;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyContainer": `AboutKhanjyan_bodyContainer__Gw705`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "../src/assets/styles/main.scss";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Main from "./pages/main/Main";
import AboutKhanjyan from "./pages/aboutKhanjyanPage/AboutKhanjyan";
import HeaderMenu from "./layout/responsiveHeader/HeaderMenu";
import useIsMobile from "./hooks/useIsMobile";
import NewsPage from "./pages/newsPage/NewsPage";
import { LanguageProvider, useLanguage } from "./contexts/LanguageContext";
import ErrorPage from "./pages/errorPage/ErrorPage";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return null;
};

const App = () => {
  const mobile = useIsMobile();
  const { language } = useLanguage();
  const [showNav, setShowNav] = useState(true);

  return (
    <LanguageProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          {showNav && (!mobile ? <Header /> : <HeaderMenu />)}
          <Routes>
            <Route path="/:lng" element={<Main />} />
            <Route path="/:lng/about-khanjyan" element={<AboutKhanjyan />} />
            <Route path="/:lng/news" element={<NewsPage />} />
            <Route
              path="/"
              element={<Navigate to={`/${language.value}`} replace />}
            />
            <Route
              path="*"
              element={<ErrorPage funcNav={(state) => setShowNav(state)} />}
            />
          </Routes>
          {showNav && !mobile && <Footer />}
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;
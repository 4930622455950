import React from "react";
import styles from "./NewsInfo.module.scss";
import arrowRight from "../../assets/images/icons/arrowRight.svg";
import useIsMobile from "../../hooks/useIsMobile";

const NewsInfo = ({ newsObject }) => {
  const mobile = useIsMobile();

  return (
    <div className={styles.newsItemContainer}>
      <div className={styles.newsItem}>
        {mobile && <p className={styles.newsDate}>{newsObject.newsDate}</p>}
        <div className={styles.newsImage}>
          <img src={newsObject.newsImage} alt="News 1" />
        </div>
        <div className={styles.newsContent}>
          {!mobile && <p className={styles.newsDate}>{newsObject.newsDate}</p>}
          <div className={styles.newsContentBody}>
            <h2 className={styles.newsTitle}>{newsObject.newsTitle}</h2>
            <div className={styles.newsTextConatiner}>
              <p className={styles.newsText}>{newsObject.newsText}</p>
            </div>
          </div>
          <a
            href={newsObject.href}
            target="_blank"
            rel="noreferrer"
            className={styles.readMore}
          >
            <img src={arrowRight} alt="arrow-right" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsInfo;

import React from "react";
import styles from "./AboutKhanjyan.module.scss";
import ScrolledComponent from "../../components/scrolledComponent/ScrolledComponent";
import khanjyan1 from "../../assets/images/fakeImages/khanjyan1.png";
import khanjyan2 from "../../assets/images/fakeImages/khanjyan2.png";
import khanjyan3 from "../../assets/images/fakeImages/khanjyan3.png";

import AboutIntroduction from "../../components/aboutPageIntroduction/AboutIntroduction";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

const AboutKhanjyan = () => {
  const { t } = useTranslation("aboutKhanjyan");
  const mobile = useIsMobile();

  return (
    <div className={styles.aboutKhanjyanPage}>
      <AboutIntroduction />
      <div className={styles.bodyContainer}>
        <ScrolledComponent
          imageSrc={khanjyan1}
          title={t("topic1Header")}
          paragraphs={[
            "Located in the very heart of Dilijan, nestled on one of its picturesque slopes, 'Aghasi Khanjyan's Villa' stands as one of the city's most historic and renowned structures.",
            "Constructed in the Soviet era, the origin of the building is deeply connected with Khanjyan's vision for the economic and cultural advancement of Armenia.",
            "Initially, it served as a summer house for Aghasi Khanjyan, the First Secretary of the Central Committee, who, similar to the esteemed author Maxim Gorky, was seeking relief from pneumonia. Following the visit of the renowned Soviet author, a street in Dilijan was named after Maxim Gorky. It is on this street that 'Khanjyan's villa' was constructed.",
            "According to various accounts, the building never fulfilled its intended use. Khanjyan spent just a single night in this country house before leaving for Georgia. There, according to another narrative, he was killed by Lavrenti Beria, the Marshal of the Soviet Union, and the First Secretary of the Central Committee of Transcaucasia and Georgia.",
            "Despite the untimely death of Khanjyan, his villa experienced several transformations, evolving into a retreat for the high-ranking officials of the republic. As the years passed, this building emerged as a pivotal symbol of Dilijan's architectural heritage.",
            "In the late 1980s and early 1990s, the vicinity of Khanjyan's villa was utilized for state-run summer camps and educational programs. Yet, as activities thrived in the buildings nearby, Khanjyan's once magnificent residence was deteriorating, posing a threat to the preservation of an important piece of Dilijan's history.",
            "Now, Khanjyan's villa stands as a testament to Dilijan's history, attracting both locals and tourists with its distinct architectural design and rich past. However, it is unfortunately confronted with the challenge of irreversible deterioration.",
            "You can read more about Dilijan's most enigmatic building and the significant personalities in its history by following the link to the dedicated article.",
          ]}
          applyParallax={mobile ? false : true}
        />
        <ScrolledComponent
          imageSrc={khanjyan2}
          title="AGHASI KHANJYAN"
          paragraphs={[
            "Aghasi Khanjyan, a prominent figure in Soviet Armenia, holds a distinguished position in the Armenian history due to his profound love to the Armenian language, literature, and history.",
            "Within the Armenian state establishment, Khanjyan played a pivotal role and made significant contributions toward implementing the development programs of the ASSR, thereby facilitating the victory of the cultural revolution and overseeing the advancement of public education.",
            "Khanjyan's life journey traverses turbulent periods characterized by upheavals and ideological fervor.",
            "Despite facing numerous arrests by the Armenian authorities due to his revolutionary activities, Khanjyan's determination remained unyielding.",
            "After holding significant positions within the Communist Party of Armenia, Khanjyan, at the age of 29, assumed the position of the first secretary. Under his leadership, Armenia experienced prosperity, especially in the agrarian policy.",
            "From conversations, it's evident that people held deep love and trust for their leader.",
            "The first secretary of the Central Committee maintained close bonds with the poet Yeghishe Charents. Following Khanjyan's death, Charents composed the tragic series 'The Dauphin Nairakan: Seven Sonnets to Aghasi Khanjyan,' identifying himself as 'the last dauphin of Nairyan's speech.'",
          ]}
          applyParallax={mobile ? false : true}
        />
        <ScrolledComponent
          imageSrc={khanjyan3}
          title="THE MYSTERY OF AGHASI KHANJYAN'S DEATH"
          paragraphs={[
            "Aghasi Khanjyan, a prominent figure in Soviet Armenia, holds a distinguished position in the Armenian history due to his profound love to the Armenian language, literature, and history.",
            "Within the Armenian state establishment, Khanjyan played a pivotal role and made significant contributions toward implementing the development programs of the ASSR, thereby facilitating the victory of the cultural revolution and overseeing the advancement of public education.",
            "Khanjyan's life journey traverses turbulent periods characterized by upheavals and ideological fervor.",
            "Despite facing numerous arrests by the Armenian authorities due to his revolutionary activities, Khanjyan's determination remained unyielding.",
            "After holding significant positions within the Communist Party of Armenia, Khanjyan, at the age of 29, assumed the position of the first secretary. Under his leadership, Armenia experienced prosperity, especially in the agrarian policy.",
            "From conversations, it's evident that people held deep love and trust for their leader.",
            "The first secretary of the Central Committee maintained close bonds with the poet Yeghishe Charents. Following Khanjyan's death, Charents composed the tragic series 'The Dauphin Nairakan: Seven Sonnets to Aghasi Khanjyan,' identifying himself as 'the last dauphin of Nairyan's speech.'",
          ]}
          applyParallax={mobile ? false : true}
          withoutBorder={true}
        />
      </div>
    </div>
  );
};

export default AboutKhanjyan;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__6h1Ub {
  position: absolute;
  width: 100%;
  z-index: 1000;
  padding: 30px 0;
}

.Header_headerContainer__3aLu8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.Header_logo__Th2DQ {
  font-size: 24px;
  font-weight: bold;
  color: var(--yellow-color);
}

.Header_nav__KwRnD {
  display: flex;
  gap: 40px;
}

.Header_navItem__5oy9Q {
  font-size: 1rem;
  color: var(--white-color);
  text-decoration: none;
  position: relative;
  font-weight: 400;
  text-transform: capitalize;
}
.Header_navItem__5oy9Q::after {
  content: "";
  font-weight: 600;
  transition: width 0.3s;
}
.Header_navItem__5oy9Q:hover::after {
  width: 100%;
}

.Header_language__MPGmx {
  font-size: 18px;
  color: var(--white-color);
  cursor: pointer;
}

.Header_applyButton__e9OEO {
  width: 124px;
  height: 42px;
  font-size: 16px;
  color: var(--yellow-color);
  border: 1px solid var(--yellow-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

@media only screen and (max-width: 414px) {
  .Header_header__6h1Ub {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,UAAA;EACA,cAAA;AAEF;;AAAA;EACE,eAAA;EACA,iBAAA;EACA,0BAAA;AAGF;;AAAA;EACE,aAAA;EAGA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,0BAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,eAAA;EACA,yBAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,0BAAA;EACA,qCAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE;IACE,aAAA;EADF;AACF","sourcesContent":[".header {\n  position: absolute;\n  width: 100%;\n  z-index: 1000;\n  padding: 30px 0;\n}\n.headerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 80%;\n  margin: 0 auto;\n}\n.logo {\n  font-size: 24px;\n  font-weight: bold;\n  color: var(--yellow-color);\n}\n\n.nav {\n  display: flex;\n  // width: 40%;\n  // justify-content: space-around;\n  gap: 40px;\n}\n\n.navItem {\n  font-size: 1rem;\n  color: var(--white-color);\n  text-decoration: none;\n  position: relative;\n  font-weight: 400;\n  text-transform: capitalize;\n\n  &::after {\n    content: \"\";\n    font-weight: 600;\n    transition: width 0.3s;\n  }\n\n  &:hover::after {\n    width: 100%;\n  }\n}\n\n.language {\n  font-size: 18px;\n  color: var(--white-color);\n  cursor: pointer;\n}\n\n.applyButton {\n  width: 124px;\n  height: 42px;\n  font-size: 16px;\n  color: var(--yellow-color);\n  border: 1px solid var(--yellow-color);\n  border-radius: 4px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 600;\n}\n\n@media only screen and (max-width: 414px) {\n  .header {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__6h1Ub`,
	"headerContainer": `Header_headerContainer__3aLu8`,
	"logo": `Header_logo__Th2DQ`,
	"nav": `Header_nav__KwRnD`,
	"navItem": `Header_navItem__5oy9Q`,
	"language": `Header_language__MPGmx`,
	"applyButton": `Header_applyButton__e9OEO`
};
export default ___CSS_LOADER_EXPORT___;

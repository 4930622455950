import React from 'react'
import Gallery from '../../components/gallery/Gallery';
import styles from './GalleryPage.module.scss'

const GalleryPage=()=> {
  return (
    <div className={styles.galleryPage}>
        <Gallery />
    </div>
  )
}

export default GalleryPage
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.svg";
import LanguageSelector from "../languages/LanguageSelector.module";
import { useLanguage } from "../../contexts/LanguageContext";

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { language } = useLanguage();

  const handleScroll = (id) => {
    if (location.pathname === `/${language.value}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logo}>
          <Link to={`/${language.value}`} className={styles.navItem}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <nav className={styles.nav}>
          <Link to={`/${language.value}/#home`} className={styles.navItem} onClick={() => handleScroll("home")}>
            {t('home')}
          </Link>
          <Link to={`/${language.value}/#gallery`} className={styles.navItem} onClick={() => handleScroll("gallery")}>
            {t('gallery')}
          </Link>
          <Link to={`/${language.value}/#news`} className={styles.navItem} onClick={() => handleScroll("news")}>
            {t('news')}
          </Link>
          <Link to={`/${language.value}/#faq`} className={styles.navItem} onClick={() => handleScroll("faq")}>
            {t('faq')}
          </Link>
          <Link to={`/${language.value}/#contact`} className={styles.navItem} onClick={() => handleScroll("contact")}>
            {t('contact')}
          </Link>
        </nav>
        <LanguageSelector />
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import FAQ from "../../components/faq/FAQ";

import styles from './FAQpage.module.scss'

const FAQpage = () => {
  return (
    <div className={styles.faqPage}> 
     <FAQ />
    </div>
  );
};

export default FAQpage;

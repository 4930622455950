// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs_contactUsContainer__UmC1H {
  width: 80%;
  margin: 0 auto;
  background-color: var(--white-color);
  padding: 8rem 0;
}
.ContactUs_contactUsContainer__UmC1H .ContactUs_contactUsBody__CPo9t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}
.ContactUs_contactUsContainer__UmC1H .ContactUs_imageOverlay__cb-QV {
  height: 600px;
}

@media only screen and (max-width: 414px) {
  .ContactUs_contactUsContainer__UmC1H {
    padding: 2rem 0;
  }
  .ContactUs_contactUsContainer__UmC1H .ContactUs_contactUsBody__CPo9t {
    width: 100%;
  }
  .ContactUs_contactUsContainer__UmC1H .ContactUs_contactUsBody__CPo9t .ContactUs_imageOverlay__cb-QV {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/contactUs/ContactUs.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;EACA,oCAAA;EACA,eAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,UAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE;IACE,eAAA;EACF;EAAE;IACE,WAAA;EAEJ;EADI;IACE,aAAA;EAGN;AACF","sourcesContent":[".contactUsContainer {\n  width: 80%;\n  margin: 0 auto;\n  background-color: var(--white-color);\n  padding: 8rem 0;\n  .contactUsBody {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 85%;\n  }\n  .imageOverlay {\n    height: 600px;\n  }\n}\n\n@media only screen and (max-width: 414px) {\n  .contactUsContainer {\n    padding: 2rem 0;\n    .contactUsBody {\n      width: 100%;\n      .imageOverlay {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactUsContainer": `ContactUs_contactUsContainer__UmC1H`,
	"contactUsBody": `ContactUs_contactUsBody__CPo9t`,
	"imageOverlay": `ContactUs_imageOverlay__cb-QV`
};
export default ___CSS_LOADER_EXPORT___;

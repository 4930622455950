import React from 'react';
import styles from '../Gallery.module.scss';
import prevArrowImg from '../../../assets/images/icons/prevArrowImg.svg'; 

const CustomPrevArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className={` ${styles.customArrow} ${styles.prevArrow}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={prevArrowImg} alt="Previous" className={styles.arrowImage} />
    </div>
  );
};

export default CustomPrevArrow;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/errorPage.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage_errorPage__s4-sF {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.ErrorPage_container__UZQls {
  text-align: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorPage_errorCode__qbnjB {
  font-size: 7.5rem;
  font-weight: 600;
  color: var(--dark-grey);
}

.ErrorPage_errorMessage__8cInS {
  font-size: 1.5rem;
  color: var(--dark-grey);
  font-weight: 700;
}

.ErrorPage_homeButton__P10QN {
  background-color: var(--yellow-color);
  color: var(--black-text);
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 300ms;
  border-radius: 2px;
  text-transform: uppercase;
  width: 178px;
  height: 42px;
  border: 1px solid var(--yellow-color);
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ErrorPage_homeButton__P10QN:hover {
  background-color: unset;
  border: 1px solid var(--yellow-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/errorPage/ErrorPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,yDAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qCAAA;EACA,wBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,kCAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,qCAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,uBAAA;EACA,qCAAA;AACJ","sourcesContent":[".errorPage {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: white;\n}\n\n.container {\n  text-align: center;\n  background-image: url(\"../../assets/images/errorPage.png\");\n  background-position: center;\n  width: 80vw;\n  height: 90vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.errorCode {\n  font-size: 7.5rem;\n  font-weight: 600;\n  color: var(--dark-grey);\n}\n\n.errorMessage {\n  font-size: 1.5rem;\n  color: var(--dark-grey);\n  font-weight: 700;\n}\n\n.homeButton {\n  background-color: var(--yellow-color);\n  color: var(--black-text);\n  font-size: 0.875rem;\n  font-weight: 600;\n  border: none;\n  cursor: pointer;\n  transition: background-color 300ms;\n  border-radius: 2px;\n  text-transform: uppercase;\n  width: 178px;\n  height: 42px;\n  border: 1px solid var(--yellow-color);\n  margin-top: 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    background-color: unset;\n    border: 1px solid var(--yellow-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `ErrorPage_errorPage__s4-sF`,
	"container": `ErrorPage_container__UZQls`,
	"errorCode": `ErrorPage_errorCode__qbnjB`,
	"errorMessage": `ErrorPage_errorMessage__8cInS`,
	"homeButton": `ErrorPage_homeButton__P10QN`
};
export default ___CSS_LOADER_EXPORT___;

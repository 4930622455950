import React from "react";
import NewsInfo from "./NewsInfo";
import styles from "./NewsSection.module.scss";
import newsImage1 from "../../assets/images/fakeImages/newsImage1.png";
import newsImage2 from "../../assets/images/fakeImages/newsImage2.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../contexts/LanguageContext";


const NewsSection = ({ fromNewsSection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const newsObject = {
    news1: {
      newsDate: "06 JUNE, 2024",
      newsTitle: t('news2Title'),
      newsText:t('news2Desc'),
      newsImage: newsImage1,
      href: "https://tavushmedia.am/hy/dilijanum-gtnvogh-aghasi-xanjyani-amaranocum-hetazotakan-ashxatankhner-en-irakanacvum/",
    },
    news2: {
      newsDate: "29 FEBRUARY, 2024",
      newsTitle: t('news1Title'),
      newsText:t('news1Desc'),
      newsImage: newsImage2,
      href: "https://urbanista.am/dilijan-khanjyan-house",
    },
  };
  
  const handleNavigate = () => {
    navigate(`/${language.value}/news`);
  };

  return (
    <div
      className={`${styles.newsSection} ${
        !fromNewsSection && styles.newsContainer
      }`}
    >
      <div className={styles.newsSectionHeaderContainer}>
        <div className={styles.newsSectionHeader}>
          <div>
            <h1 className={styles.title}>{t('news')}</h1>
          </div>
          {fromNewsSection && (
            <div className={styles.descriptionContainer}>
              <p className={styles.description}>
                {t('newsSectionHeader')}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.newsSectionContainer}>
        <NewsInfo newsObject={newsObject.news1} />
        <NewsInfo newsObject={newsObject.news2} />
      </div>
      {fromNewsSection && (
        <div className={styles.btnContainer} onClick={handleNavigate}>
          <button type="submit" className={styles.viewAllButton}>
            {t('allNews')}
          </button>
        </div>
      )}
    </div>
  );
};

export default NewsSection;

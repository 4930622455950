import React from "react";
import styles from "./AboutCompany.module.scss";
import companyLogo from "../../assets/images/companyLogo.png";
import { useTranslation } from "react-i18next";

const AboutCompany = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.aboutCompany}>
      <div className={styles.hero}>
        <div className={styles.overlay}>
          <div className={styles.logoContainer}>
            <img
              src={companyLogo}
              alt="Green Rock Logo"
              className={styles.logo}
            />
          </div>
          <div className={styles.textContainer}>
            <p className={styles.description}>{t("aboutCompany")}</p>
            <a href="https://greenrock.am/" target="_blank" rel="noreferrer">
              <button className={styles.button}>{t("readMore")}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;

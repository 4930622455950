import React, { useState } from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import styles from "./LanguageSelector.module.scss";
import lngArrow from "../../assets/images/icons/lngArrow.svg";
import useIsMobile from "../../hooks/useIsMobile";

const LanguageSelector = ({ handleClose }) => {
  const mobile = useIsMobile();
  const { language, changeLanguage } = useLanguage();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const languageNames = {
    en: 'English',
    arm: 'Հայերեն',
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (languageCode) => {
    changeLanguage(languageCode);
    navigate(`/${languageCode}`);
    setIsOpen(false);
    mobile && handleClose();
  };

  return (
    <div className={styles.languageSelector}>
      <div
        className={`${styles.selectedLanguage} ${isOpen ? styles.open : ''}`}
        onClick={toggleDropdown}
      >
        {languageNames[language.value]}{" "}
        <img src={lngArrow} className={`${styles.icon} ${isOpen ? styles.open : ''}`} alt="language" />
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.option} onClick={() => selectLanguage("en")}>
            English {language.value === 'en' && <span className={styles.checkmark}>✓</span>}
          </div>
          <div className={styles.option} onClick={() => selectLanguage("arm")}>
            Հայերեն {language.value === 'arm' && <span className={styles.checkmark}>✓</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsSection_newsPage__V2jYR {
  background-color: var(--background-white);
}`, "",{"version":3,"sources":["webpack://./src/pages/newsSection/NewsSection.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF","sourcesContent":[".newsPage {\n  background-color: var(--background-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsPage": `NewsSection_newsPage__V2jYR`
};
export default ___CSS_LOADER_EXPORT___;

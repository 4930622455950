import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import instagram from "../../assets/images/icons/instagram.svg";
import fb from "../../assets/images/icons/fb.svg";
import linkedIn from "../../assets/images/icons/linkedIn.svg";
import logo from "../../assets/images/logo.svg";
import styles from "./Footer.module.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const location = useLocation();
  const { language } = useLanguage();

  const handleScroll = (id) => {
    if (location.pathname === `/${language.value}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div className={styles.logo}>
            <Link to={`/${language.value}`} className={styles.navItem}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className={styles.copyright}>{year}© All rights reserved.</div>
        </div>
        <div className={styles.middleSection}>
          <Link to={`/${language.value}/#home`} className={styles.link} onClick={() => handleScroll("home")}>
            {t("home")}
          </Link>
          <Link to={`/${language.value}/#gallery`} className={styles.link} onClick={() => handleScroll("gallery")}>
            {t("gallery")}
          </Link>
          <Link to={`/${language.value}/#news`} className={styles.link} onClick={() => handleScroll("news")}>
            {t("news")}
          </Link>
          <Link to={`/${language.value}/#faq`} className={styles.link} onClick={() => handleScroll("faq")}>
            {t("faq")}
          </Link>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.contactInfo}>
            <a href="mailto:welcome@greenrock.am" target="_blank" rel="noreferrer">
              welcome@greenrock.am
            </a>
            <a href="tel:+37441280280" target="_blank" rel="noreferrer">
              041 280 280
            </a>
          </div>
          <div className={styles.socialIcons}>
            <a
              href="https://www.instagram.com/greenrock_managementgroup/"
              target="_blank"
              rel="noreferrer"
              className={styles.icon}
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.facebook.com/greenrockmanagementgroup1"
              target="_blank"
              rel="noreferrer"
              className={styles.icon}
            >
              <img src={fb} alt="Facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/greenrockmanagementgroup/"
              target="_blank"
              rel="noreferrer"
              className={styles.icon}
            >
              <img src={linkedIn} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className={styles.addressSection}>
          <div className={styles.address}>{t('address')}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrolledComponent_container__1v8fG {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6.25rem;
  border-bottom: 1px solid var(--light-grey);
  transform: none !important;
}

.ScrolledComponent_withoutBorder__fyHAL {
  border-bottom: unset;
}

.ScrolledComponent_title__RI\\+w6 {
  width: 48%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ScrolledComponent_title__RI\\+w6 h1 {
  font-size: 3rem;
  margin: 1.5rem 0;
  font-weight: 700;
  color: var(--dark-grey);
}

.ScrolledComponent_imageContainer__V2Lwj {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.ScrolledComponent_image__DqKZ3 {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.ScrolledComponent_bodyConatiner__MyInG {
  width: 45%;
  margin-top: 13rem;
}

.ScrolledComponent_textContainer__dkhkU p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
  overflow: hidden;
}

@media only screen and (max-width: 414px) {
  .ScrolledComponent_container__1v8fG {
    flex-direction: column;
    align-items: center;
    transform: none !important;
  }
  .ScrolledComponent_bodyConatiner__MyInG {
    width: 95%;
    margin-top: 2rem;
  }
  .ScrolledComponent_title__RI\\+w6 {
    width: 100%;
    position: unset;
  }
  .ScrolledComponent_title__RI\\+w6 h1 {
    font-size: 2.5rem;
  }
  .ScrolledComponent_imageContainer__V2Lwj {
    width: 100%;
    margin-top: 1rem;
  }
  .ScrolledComponent_textContainer__dkhkU {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/scrolledComponent/ScrolledComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,0CAAA;EACA,0BAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AACA;EACE,UAAA;EACA,gBAAA;EACA,MAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AACA;EACE,UAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AAEF;;AACA;EACE;IACE,sBAAA;IACA,mBAAA;IACA,0BAAA;EAEF;EAAA;IACE,UAAA;IACA,gBAAA;EAEF;EAAA;IACE,WAAA;IACA,eAAA;EAEF;EADE;IACE,iBAAA;EAGJ;EAAA;IACE,WAAA;IACA,gBAAA;EAEF;EAAA;IACE,WAAA;EAEF;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 6.25rem;\n  border-bottom: 1px solid var(--light-grey);\n  transform: none !important\n\n}\n.withoutBorder {\n  border-bottom: unset;\n}\n.title {\n  width: 48%;\n  position: sticky;\n  top: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.title h1 {\n  font-size: 3rem;\n  margin: 1.5rem 0;\n  font-weight: 700;\n  color: var(--dark-grey);\n}\n\n.imageContainer {\n  width: 100%;\n  height: auto;\n  margin-top: 2rem;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  border-radius: 4px;\n}\n\n.bodyConatiner {\n  width: 45%;\n  margin-top: 13rem;\n}\n\n.textContainer p {\n  font-size: 1rem;\n  line-height: 1.6;\n  color: #333;\n  margin-bottom: 1rem;\n  overflow: hidden;\n}\n\n@media only screen and (max-width: 414px) {\n  .container {\n    flex-direction: column;\n    align-items: center;\n    transform: none !important\n  }\n  .bodyConatiner {\n    width: 95%;\n    margin-top: 2rem;\n  }\n  .title {\n    width: 100%;\n    position: unset;\n    h1 {\n      font-size: 2.5rem;\n    }\n  }\n  .imageContainer {\n    width: 100%;\n    margin-top: 1rem;\n  }\n  .textContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScrolledComponent_container__1v8fG`,
	"withoutBorder": `ScrolledComponent_withoutBorder__fyHAL`,
	"title": `ScrolledComponent_title__RI+w6`,
	"imageContainer": `ScrolledComponent_imageContainer__V2Lwj`,
	"image": `ScrolledComponent_image__DqKZ3`,
	"bodyConatiner": `ScrolledComponent_bodyConatiner__MyInG`,
	"textContainer": `ScrolledComponent_textContainer__dkhkU`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useRef, useEffect } from "react";
import styles from "./ScrolledComponent.module.scss";

const ScrolledComponent = ({ imageSrc, title, paragraphs, applyParallax,withoutBorder }) => {
  // const textRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!applyParallax) return;
  //     const scrollPosition = window.scrollY;
  //     if (textRef.current) {
  //       textRef.current.style.transform = `translateY(-${
  //         scrollPosition * 0.1
  //       }px)`;
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [applyParallax]);

  return (
    <div className={`${styles.container} ${withoutBorder && styles.withoutBorder}`}>
      <div className={styles.title}>
        <h1>{title}</h1>
        <div className={styles.imageContainer}>
          <img src={imageSrc} alt={title} className={styles.image} />
        </div>
      </div>
      <div className={styles.bodyConatiner }>
        <div className={styles.textContainer} >
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrolledComponent;
import React from 'react';
import styles from '../Gallery.module.scss';
import sliderRightArrow from '../../../assets/images/icons/sliderRigtarrow.svg';

const CustomNextArrow = (props) => {
  const {  style, onClick } = props;
  return (
    <div
      className={`${styles.customArrow} ${styles.nextArrow}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={sliderRightArrow} alt="Next" className={styles.arrowImage} />
    </div>
  );
};

export default CustomNextArrow;

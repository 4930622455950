import React from "react";
import AboutCompany from "../../components/aboutCompany/AboutCompany";
import ContactUs from "../../components/contactUs/ContactUs";
import FAQpage from "../faq/FAQpage";
import Introduction from "../../components/introduction/Introduction";
import GalleryPage from "../gallery/GalleryPage";
import NewsPage from "../newsSection/NewsSection";
import AboutProject from "../../components/aboutProject/AboutProject";

const Main = () => {
  return (
    <>
      <section id="home">
        <Introduction />
      </section>
      <section id="aboutProject">
        <AboutProject />
      </section>
      <AboutCompany />
      <section id="news">
        <NewsPage />
      </section>
      <section id="faq">
        <FAQpage />
      </section>

      <section id="gallery">
        <GalleryPage />
      </section>
      <section id="contact">
        <ContactUs />
      </section>
    </>
  );
};

export default Main;

import React from "react";
import styles from "./ContactUs.module.scss";
import contactUsImg from '../../assets/images/contactUsImg.png';

import FormGroup from "./FormGroup";

const ContactUs = () => {
  return (
    <div className={styles.contactUsContainer}>
      <div className={styles.contactUsBody}>
      <div className={styles.imageOverlay}>
        <img
          src={contactUsImg}
          alt="Background"
          className={styles.backgroundImage}
        />
      </div>
     <FormGroup />
      </div>
    </div>
  );
};

export default ContactUs;

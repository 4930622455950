import React from "react";
import styles from "./AboutIntroduction.module.scss";
import buttonArrow from "../../assets/images/icons/buttonArrow.svg";
import { useTranslation } from "react-i18next";

const AboutIntroduction = () => {
  const { t } = useTranslation();
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 700,
      behavior: "smooth",
    });
  };
  return (
    <div className={styles.homePage}>
      <div className={styles.introductionContainer}>
        <div className={styles.overlay}></div>
        <div className={styles.content}>
          <h1 className={styles.title}>{t("aboutKhanjyan")}</h1>
          <p className={styles.subtitle}>
            {t('aboutKhanjyanBody')}
          </p>
          <button className={styles.button} onClick={handleScroll}>
            {t("readMore")}
            <img src={buttonArrow} alt="buttonArrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutIntroduction;

import React from "react";
import NewsSection from "../../components/news/NewsSection";
import styles from "./NewsSection.module.scss";

const NewsPage = () => {
  return (
    <div className={styles.newsPage}>
      <NewsSection fromNewsSection={true} />
    </div>
  );
};

export default NewsPage;

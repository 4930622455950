import React from "react";
import styles from "./AboutProject.module.scss";
import aboutProject from "../../assets/images/aboutProject.png";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

const AboutProject = () => {
  const mobile = useIsMobile();
  const {t } = useTranslation()
  return (
    <div className={styles.aboutProjectContainer}>
      {!mobile ? (
        <div className={styles.aboutProject}>
          <div className={styles.content}>
            <h1 className={styles.title}>{t('aboutKhanjyanVilla')}</h1>
            <p className={styles.description}>
             {t('aboutKhanjyanDesc')}
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img
              src={aboutProject}
              alt="Aghasi Khanjyan's Villa"
              className={styles.image}
            />
          </div>
        </div>
      ) : (
        <div className={styles.aboutProjectMobile}>
          <div className={styles.contentMobile}>
            <h1 className={styles.titleMobile}>{t('aboutKhanjyanVilla')}</h1>
          </div>
          <div className={styles.imageContainerMobile}>
            <img
              src={aboutProject}
              alt="Aghasi Khanjyan's Villa"
              className={styles.image}
            />
          </div>
          <div className={styles.contentMobile}>
            <p className={styles.descriptionMobile}>
            {t('aboutKhanjyanDesc')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutProject;

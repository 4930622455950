import React from "react";
import Slider from "react-slick";
import styles from "./Gallery.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../assets/images/fakeImages/image1.png";
import image2 from "../../assets/images/fakeImages/image2.png";
import image3 from "../../assets/images/fakeImages/image3.png";
import image4 from "../../assets/images/fakeImages/image4.png";
import CustomNextArrow from "./buttons/CustomNextArrow";
import CustomPrevArrow from "./buttons/CustomPrevArrow";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const { t } = useTranslation();
  const galleryItems = [
    {
      imageUrl: image1,
      // name: t('galleryImage1Name'),
      description: t("galleryImage1Desc"),
    },
    {
      imageUrl: image2,
      // name: t('galleryImage1Name'),
      description: t("galleryImage1Desc"),
    },
    {
      imageUrl: image3,
      // name: t('galleryImage2Name'),
      description: t("galleryImage2Desc"),
    },
    {
      imageUrl: image4,
      // name: t('galleryImage1Name'),
      description: t("galleryImage1Desc"),
    },
  ];
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: ` ${styles.customSlickSlider}`,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.gallery}>
      <div className={styles.galleryHeadarContainer}>
        <div className={styles.gelleryHeader}>
          <div>
            <h1 className={styles.title}>{t("gallery")}</h1>
          </div>
          <div className={styles.descriptionContainer}>
            <p className={styles.description}>{t("gallerySectionBody")}</p>
          </div>
        </div>
      </div>
      <Slider {...settings} className={styles.slider}>
        {galleryItems.map((item, index) => (
          <div className={styles.galleryItem} key={index}>
            <img src={item.imageUrl} alt={item.name} className={styles.image} />
            <div className={styles.info}>
              {/* <h3 className={styles.name}>{item.name}</h3> */}
              <p className={styles.itemDescription}>{item.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;

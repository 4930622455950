import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./HeaderMenu.module.scss";
import logo from "../../assets/images/logo.svg";
import instagram from "../../assets/images/icons/instagram.svg";
import fb from "../../assets/images/icons/fb.svg";
import linkedIn from "../../assets/images/icons/linkedIn.svg";
import LanguageSelector from "../languages/LanguageSelector.module";
import { useLanguage } from "../../contexts/LanguageContext";

const HeaderMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { language } = useLanguage();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleScroll = (id) => {
    if (location.pathname === `/${language.value}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      toggleMenu();
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isMenuOpen]);

  return (
    <header className={styles.header}>
      <div
        className={`${styles.headerContainer} ${
          isMenuOpen && styles.headerContainerBlack
        } `}
      >
        <Link to={`/${language.value}`} className={styles.logoContainer}>
          <img src={logo} alt="Khanjyan Villa" className={styles.logo} />
        </Link>
        <div className={styles.menuToggle}>
          <div className={styles.hamburger} onClick={toggleMenu}></div>
        </div>
      </div>
      {isMenuOpen && (
        <nav className={styles.navMenu}>
          <div className={styles.languageSwitch}>
            <LanguageSelector handleClose={toggleMenu} />
          </div>
          <div className={styles.navList}>
            <Link
              to={`/${language.value}/#home`}
              className={styles.navItem}
              onClick={() => handleScroll("home")}
            >
              Home
            </Link>
            <Link
              to={`/${language.value}/#gallery`}
              className={styles.navItem}
              onClick={() => handleScroll("gallery")}
            >
              Gallery
            </Link>
            <Link
              to={`/${language.value}/#news`}
              className={styles.navItem}
              onClick={() => handleScroll("news")}
            >
              News
            </Link>
            <Link
              to={`/${language.value}/#faq`}
              className={styles.navItem}
              onClick={() => handleScroll("faq")}
            >
              FAQ
            </Link>
            <Link
              to={`/${language.value}/#contact`}
              className={styles.navItem}
              onClick={() => handleScroll("contact")}
            >
              Contact
            </Link>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.contactsContainer}>
              <a
                href="mailto:welcome@greenrock.am"
                target="_blank"
                rel="noreferrer"
              >
                welcome@greenrock.am
              </a>
              <a href="tel:+37441280280" target="_blank" rel="noreferrer">
                041 280 280
              </a>
            </div>
            <div className={styles.socialIcons}>
              <a
                href="https://www.instagram.com/greenrock_managementgroup/"
                target="_blank"
                rel="noreferrer"
                className={styles.icon}
              >
                <img src={instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/greenrockmanagementgroup1"
                target="_blank"
                rel="noreferrer"
                className={styles.icon}
              >
                <img src={fb} alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/greenrockmanagementgroup/"
                target="_blank"
                rel="noreferrer"
                className={styles.icon}
              >
                <img src={linkedIn} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default HeaderMenu;

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./Introduction.module.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const Introduction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const handleNavigate = () => {
    navigate(`/${language.value}/about-khanjyan`);
  };

  return (
    <div className={styles.homePage}>
      <div className={styles.hero}>
        <div className={styles.overlay}></div>
        <div className={styles.content}>
          <h1 className={styles.title}>{t("introductionHeader")}</h1>
          <p className={styles.subtitle}>{t("introductionBody")}</p>
          <button className={styles.button} onClick={handleNavigate}>
            {t('aboutKhanjyan')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Introduction;

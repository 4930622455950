import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import aboutKhanjyanEN from './locales/en/aboutKhanjyan.json';

import translationARM from './locales/arm/translation.json';
import aboutKhanjyanARM from './locales/arm/aboutKhanjyan.json';

const resources = {
  en: {
    translation: translationEN,
    aboutKhanjyan: aboutKhanjyanEN,
  },
  arm: {
    translation: translationARM,
    aboutKhanjyan: aboutKhanjyanARM,
  },
};

const storedLang = localStorage.getItem('language') || 'en';
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: storedLang,
    fallbackLng: 'en',
    ns: ['translation', 'aboutKhanjyan'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FormGroup.module.scss";

const FormGroup = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.surname) errors.surname = "Surname is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
    if (!formData.message) errors.message = "Message is required";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      try {
        console.log("Form submitted successfully:", formData);
        setIsSubmitted(true);
        setSubmitError(false);
        setFormErrors({});
        setFormData({
          name: "",
          surname: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } catch (error) {
        setSubmitError(true);
        setIsSubmitted(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className={styles.contactForm}>
      <h1 className={styles.title}>{t("contact")}</h1>

      <form onSubmit={handleSubmit} noValidate>
        <div className={styles.formGroup}>
          <div className={styles.field}>
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={formErrors.name ? styles.errorInput : ""}
            />
            {formErrors.name && (
              <span className={styles.error}>{formErrors.name}</span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="surname">{t("surname")}</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              className={formErrors.surname ? styles.errorInput : ""}
            />
            {formErrors.surname && (
              <span className={styles.error}>{formErrors.surname}</span>
            )}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.field}>
            <label htmlFor="email">{t("email")}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={formErrors.email ? styles.errorInput : ""}
            />
            {formErrors.email && (
              <span className={styles.error}>{formErrors.email}</span>
            )}
          </div>
          <div className={styles.field}>
            <label htmlFor="phoneNumber">{t("tell")}</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className={formErrors.phoneNumber ? styles.errorInput : ""}
            />
            {formErrors.phoneNumber && (
              <span className={styles.error}>{formErrors.phoneNumber}</span>
            )}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.field}>
            <label htmlFor="message">{t("message")}</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={formErrors.message ? styles.errorInput : ""}
            ></textarea>
            {formErrors.message && (
              <span className={styles.error}>{formErrors.message}</span>
            )}
          </div>
        </div>
        {isSubmitted && (
          <div className={styles.successMessage}>
            Thank you! Form has been successfully submitted.
          </div>
        )}
        {submitError && (
          <div className={styles.errorMessage}>
            Oops! Something went wrong while submitting the form. Please try
            again later.
          </div>
        )}
        <button type="submit" className={styles.button}>
          {t("sendMessage")}
        </button>
      </form>
    </div>
  );
};

export default FormGroup;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GalleryPage_galleryPage__hYkEM {
  background-color: var(--background-white);
}`, "",{"version":3,"sources":["webpack://./src/pages/gallery/GalleryPage.module.scss"],"names":[],"mappings":"AAAA;EACA,yCAAA;AACA","sourcesContent":[".galleryPage{\nbackground-color: var(--background-white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"galleryPage": `GalleryPage_galleryPage__hYkEM`
};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import NewsSection from "../../components/news/NewsSection";
import NewsHeader from "../../components/newsHeader/NewsHeader";

const NewsPage = () => {
  return (
    <>
      <NewsHeader />
      <NewsSection fromNewsSection={false} />
    </>
  );
};

export default NewsPage;

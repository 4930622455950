import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./ErrorPage.module.scss";

const ErrorPage = ({ funcNav }) => {
  useEffect(() => {
    funcNav(false);
  }, [funcNav]);

  return (
    <div className={styles.errorPage}>
      <div className={styles.container}>
        <p className={styles.errorMessage}>ERROR</p>
        <h1 className={styles.errorCode}>404</h1>
        <p className={styles.errorMessage}>PAGE NOT FOUND</p>
        <Link to="/en" className={styles.homeButton} onClick={() => funcNav(true)}>
          GO HOME
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;

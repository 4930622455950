import React, { useState } from "react";
import styles from "./FAQ.module.scss";
import arrowUp from "../../assets/images/icons/arrowUp.svg";
import arrowDown from "../../assets/images/icons/arrowDown.svg";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const {t} = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);
  //fake data
  const questions = [
    {
      question: t('question1'),
      answer:t('question1Answer')    },
    {
      question: t('question2'),
      answer:t('question2Answer') 
    },
    // {
    //   question: t('question3'),
    //   answer:t('question3Answer') 
    // },
    // {
    //   question: t('question4'),
    //   answer:t('question4Answer') 
    // },
    {
      question: t('question5'),
      answer:t('question5Answer') 
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqSection}>
      <div className={styles.faqSectionContainer}>
        <div className={styles.header}>
          <h1 className={styles.title}>{t('faqQuestions')}</h1>
          <p className={styles.subtitle}>{t('faqSubtitile')}</p>
        </div>
        <div className={styles.questions}>
          {questions.map((item, index) => (
            <div
              key={index}
              className={`${styles.questionItem} ${
                activeIndex === index ? styles.active : ""
              }`}
              onClick={() => handleToggle(index)}
            >
              <div className={styles.question}>
                <div className={styles.questionText}>{item.question}</div>
                <span className={styles.icon}>
                  {activeIndex === index ? (
                    <img src={arrowUp} alt="arrowUp" />
                  ) : (
                    <img src={arrowDown} alt="arrowDown" />
                  )}
                </span>
              </div>
              {activeIndex === index && (
                <div className={styles.answer}>{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
